<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Users</span>
      </h3>
      <div class="card-toolbar">
        <router-link
          :to="{ name: 'user/create' }"
          class="btn btn-info font-weight-bolder font-size-sm mr-3"
        >
          Create User
        </router-link>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 120px" class="pl-7">
                  <span class="text-dark-75">User</span>
                </th>
                <th style="min-width: 120px">Email</th>
                <th style="min-width: 100px">Phone</th>
                <th style="min-width: 100px">Birthday</th>
                <th style="min-width: 100px">Role</th>
                <th style="min-width: 100px">Activity</th>
                <th style="min-width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td class="pl-0 py-8">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="symbol-label">
                          <i class="fa fa-user"></i>
                        </span>
                      </div>
                      <div>
                        <a
                          href="#"
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >{{ item.firstName }} {{ item.lastName }}</a
                        >
                        <!-- <span class="text-muted font-weight-bold d-block">{{
                          item.desc
                        }}</span> -->
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.email }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.phone }}</span
                    >
                    <!-- <span class="text-muted font-weight-bold">{{
                      item.status2
                    }}</span> -->
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.birthday }}</span
                    >
                    <!-- <span class="text-muted font-weight-bold">{{
                      item.company_desc
                    }}</span> -->
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.roleId == 1 ? "Admin" : "Agent" }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.workerActivity }}</span
                    >
                  </td>
                  <td class="pr-0 text-right">
                    <router-link
                      :to="{ name: 'user/edit', params: { id: item.id } }"
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      >Edit</router-link
                    >
                    <button
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-3"
                      v-b-modal="`delete-modal-${item.id}`"
                    >
                      Delete
                    </button>
                    <b-modal
                      :id="`delete-modal-${item.id}`"
                      :ref="`delete-modal-${item.id}`"
                      hide-footer
                      hide-header
                    >
                      <p class="my-4">Are you sure you want to delete user?</p>
                      <b-button
                        class="mt-3"
                        @click="
                          deleteUser(item.id);
                          $bvModal.hide(`delete-modal-${item.id}`);
                        "
                        >Delete</b-button
                      >
                    </b-modal>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_USERS_LIST,
  DELETE_USER
} from "@/core/services/store/user.module.js";

export default {
  name: "user-list",
  data: () => ({}),
  mounted() {
    this.$store.dispatch(GET_USERS_LIST);
  },
  computed: {
    ...mapState({
      list: state => state.user.users
    })
  },
  methods: {
    deleteUser(userId) {
      this.$store.dispatch(DELETE_USER, userId).then(data => {
        this.$store.dispatch("showSnackbar", {
          message: "User deleted successfully",
          color: "success"
        });
      });
    }
  }
};
</script>
