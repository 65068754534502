<template>
  <div>
    <!-- begin:: Content Head -->
    <KTSubheader v-bind:title="'Users'">
      <template v-slot:button-content>
        <!-- <router-link
          :to="{ name: 'user/create' }"
          class="btn btn-info font-weight-bolder font-size-sm mr-3"
        >
          Create User
        </router-link> -->
      </template>
    </KTSubheader>
    <!-- end:: Content Head -->
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <UserList></UserList>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import UserList from "../../content/user/list/UserList.vue";

export default {
  name: "users",
  components: {
    KTSubheader,
    UserList
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
  }
};
</script>
